html {
    scroll-behavior: smooth; /* Smooth scroll */
    height: 100%; /* Full height for HTML document */
    overflow-x: hidden; /* Prevent horizontal scroll */
}

body {
    margin: 20px;
}

/* Header */
.header {
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    font-size: 18px;
}

.header-image img {
    width: 400px;
    height: auto;
    border-radius: 10px;
    max-width: 90vw;
    margin-right: 20px;
}

.header-text {
    max-width: 400px;
}

.highlight {
    color: #cc54ff;
    font-weight: bold;
}

.icon-bar {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.icon-bar img {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* Popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    justify-content: center;
    align-items: center;
}

.popup.open {
    display: flex;
    animation: fadeIn 0.3s ease-out;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.scroll-down {
    cursor: pointer;
    text-align: center;
    left: 50%;
    margin-bottom: 200px;
}



.placeholder {
    margin-top: 100px;
    padding: 50px;
    text-align: center;
    background-color: #f7f7f7;
    width: 100%;
}

#tables {
    align-content: center;
    display: flex;
    margin-bottom: 50px;
    flex-wrap: wrap;
}

.resume-form-link {
    text-decoration: none;
    color: #cc54ff;
}