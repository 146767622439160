.table {
    border: 2px solid black;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.table-container {
    display: flex;
    flex-direction: column;
}

.table-row {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 90%;
    margin: 0 auto;
}

.table-row:last-child {
    border-bottom: none;
}

.table-row:hover {
    background-color: #f5f5f5;
}

.table-image {
    margin-right: 20px;
}

.table-content {
    display: flex;
    flex-direction: column;
}

.table-title {
    font-size: 16px;
    font-weight: bold;
}

.table-subtitle {
    font-size: 14px;
    color: gray;
}
